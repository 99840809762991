<template>
  <div>
    <POPUP titulo="Sistema de Alarmes de Incêndio" url="sdai">
        <v-img eager height="300px" src="@/assets/fotos/1.jpg"></v-img>
        <p class="pt-4" style="text-align: justify;  text-justify: inter-word;">
            A GEOTECH possui um grande know how em Sistemas de Detecção e Alarme de Incêndio, atuamos na elaboração de projetos, execução e manutenção de SDAI para clientes de vários segmentos como shoppings, hospitais, estádio de futebol entre outros.
        </p>
        <sliderObras  :listaLogos="[
            require('@/assets/parceiros/boschw.svg'),
            require('@/assets/parceiros/siemens.svg'),
            require('@/assets/parceiros/morleyp.svg')
        ]" />
    </POPUP>
    <POPUP titulo="Redes Lógicas" url="redes-logicas">
        <v-img eager height="300px" src="@/assets/fotos/2.jpg"></v-img>
        <p class="pt-4" style="text-align: justify;  text-justify: inter-word;">
            A construção de Redes Lógicas é a nossa principal atividade há mais de 15 anos, projetamos e executamos redes em Cabeamento Estruturado, links em Fibra Ótica, redes GPON e construção de Datacenters. A GEOTECH é um Canal Autorizado Solution Provider GOLD FURUKAWA, esse credenciamento nos permite oferecer aos nossos clientes acesso a programas, como o de Garantia Estendida de 25 anos em Materiais-Serviços e ao Programa Green It da FURUKAWA.
        </p>
        <sliderObras  :listaLogos="[
            require('@/assets/parceiros/furukawaw.svg'),
            require('@/assets/Icons/badge/greenit.png'),
            require('@/assets/parceiros/fcs.svg'),
        ]" />
    </POPUP>
    <POPUP titulo="Controle de Acesso" url="controle-de-acesso">
        <v-img eager height="300px" src="@/assets/fotos/3.jpg"></v-img>
        <p class="pt-4" style="text-align: justify;  text-justify: inter-word;">
            Projetos de Controle de Acesso demandam uma grande expertise para elaboração e implementação desses sistemas, o alto nível de integração com outras disciplinas voltadas para segurança, demanda um conhecimento agregado elevado para o sucesso da implantação. <br>
            Temos experiência comprovada na montagem de sistemas de Controle de Acesso e possuímos mão-de-obra especializada e treinada por nossos parceiros fabricantes.
        </p>
        <sliderObras  :listaLogos="[
            require('@/assets/parceiros/hik.svg'),
            require('@/assets/parceiros/intelbras.svg'),
            require('@/assets/parceiros/paradox.svg'),
        ]" />
    </POPUP>
    <POPUP  titulo="Sonorização de Ambientes" url="sonorizacao">
        <v-img eager height="300px" src="@/assets/fotos/5.jpg"></v-img>
        <p class="pt-4" style="text-align: justify;  text-justify: inter-word;">
            Realizamos a execução de projetos de Áudio e Vídeo para empresas como auditórios e salas de reuniões.
            Instalamos também sistema de sonorização para comunicação em grandes ambientes como hospitais, aeroportos e centro de convenções.
            Nossos técnicos são especializados em ÁudioIP, esse sistema possibilita uma instalação simplificada e um grande gerenciamento do sistema de áudio utilizando uma rede de dados do empreendimento, podendo também ser interligado a outros sistemas como o de SDAI e Telefonia.
        </p>
        <sliderObras  :listaLogos="[
            require('@/assets/Icons/SVG/logo.svg'),
        ]" />
    </POPUP>
    <POPUP titulo="CFTV" url="cftv">
        <v-img eager height="300px" src="@/assets/fotos/4.jpg"></v-img>
        <div class="pt-4" style="text-align: justify;  text-justify: inter-word;">
            Atuamos na elaboração, execução de projetos e manutenção de Sistemas de CFTV (Circuito Fechado de TV), nossos parceiros nos proporcionam trabalhar com diversos nichos de mercados atendendo a clientes de vários segmentos.
            A empresa GEOTECH disponibiliza mão-de-obra especializada treinada pelos fabricantes preparada para implementação de projetos de alta complexidade.       </div>
        <sliderObras  :listaLogos="[
            require('@/assets/parceiros/hik.svg'),
            require('@/assets/parceiros/intelbras.svg'),
        ]" />
    </POPUP>
    <POPUP titulo="Energia Solar" url="energia-solar">
        <v-img eager height="300px" src="@/assets/fotos/6.jpg"></v-img>
        <div class="pt-4" style="text-align: justify;  text-justify: inter-word;">
            A GEOTECH Geração Distribuída utiliza os melhores parceiros e as melhores tecnologias para realizar seu sonho de produzir sua própria energia limpa e economizar na sua conta de luz, temos uma equipe altamente treinada capaz de realizar a elaboração de projetos de dimensionamento e de regularização junto a concessionária, bem como a instalação de sistemas fotovoltaicos.
        </div>
        <sliderObras  :listaLogos="[
            require('@/assets/parceiros/fronius.svg'),
            require('@/assets/parceiros/hoymiles.svg'),
            require('@/assets/parceiros/intelbras-solar.svg'),
            require('@/assets/parceiros/weg.svg'),
        ]" />
    </POPUP>
    <POPUP titulo="Instalação de WIFI" url="wifi">
        <v-img eager height="300px" src="@/assets/fotos/7.png"></v-img>
        <div class="pt-4" style="text-align: justify;  text-justify: inter-word;">
            A GEOTECH é uma integradora de soluções para infraestrutura de redes wireless, apresentamos soluções inovadoras visando o gerenciamento, a segurança da informação e a diminuição dos custos informáticos, nossos projetos primam pela eficiência do sinal Wi-Fi através de um estudo (Site Survey) do espaço físico e do dimensionamento dos equipamentos a serem utilizados.
        </div>
        <sliderObras  :listaLogos="[
            require('@/assets/parceiros/intelbras.svg'),
            require('@/assets/parceiros/mikroTik.svg'),
            require('@/assets/parceiros/UBIQUITI.svg'),

        ]" />
    </POPUP>
    <CONTATO titulo="Contato" url="contato">
        <v-card eager color="white" flat class="pa-0 ma-0 rounded-0 text--black">
            <div v-if="!$vuetify.breakpoint.mobile" class="py-10 text-center">
                <v-img height="300px" width="100%" class="mb-3" contain src="@/assets/qrcode.png"></v-img>
                <h5 class="mb-3" style="color: black;">+55 (62) 3092-1626</h5>
                <v-btn depressed rounded color="primary" onclick="window.open('https://api.whatsapp.com/send?phone=556230921626&text=Ol%C3%A1,%20gostaria%20de%20conversar%20com%20um%20consultor%20da%20Geotech.', '_blank')">ou clique aqui</v-btn>
            </div>
            <div v-if="$vuetify.breakpoint.mobile" class="py-10 text-center">
                <v-btn class="mb-3" depressed rounded color="primary" onclick="window.open('https://api.whatsapp.com/send?phone=556230921626&text=Ol%C3%A1,%20gostaria%20de%20conversar%20com%20um%20consultor%20da%20Geotech.', '_blank')">clique aqui</v-btn>
                 <h5 style="color: black;">+55 (62) 3092-1626</h5>
            </div>
        </v-card>
    </CONTATO>
    <POPUP titulo="Certificados" url="certificados">
        <div class="pt-4">
        <v-row>
            <v-col cols="4">
                <v-img contain src="@/assets/Icons/badge/selo1.png"></v-img>
                <v-img contain src="@/assets/Icons/badge/selo2.png"></v-img>
            </v-col>
            <v-col cols="8">
                <div>
                    <v-card class="mb-5" color="black">
                        <v-responsive :aspect-ratio="16/9">
                            <v-card-text>
                            Imagem da empresa
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                    <div class="mb-3">
                    Somos uma empresa de Engenharia premiada, fundada e idealizada pelo Eng. Nilo Pina.
                    Há 15 anos, realizamos obras públicas e privadas no Centro-Oeste e em outras regiões do país.
                    Atendemos desde o planejamento do projeto até a execução de serviços dentro do perfil de atuação da nossa empresa, dispondo de equipe capacitada e extremamente qualificada.
                    </div>
                </div>
            </v-col>
        </v-row>
        </div>
    </POPUP>
  </div>
</template>

<script>
import POPUP from '@/components/homepage/POPUP.vue'
import CONTATO from '@/components/homepage/CONTATOPOPUP'
import sliderObras from '@/components/homepage/sliderObras.vue'


export default {
    components: {
        POPUP, CONTATO, sliderObras
    },
    data() {
        return {

        }
    },
}
</script>

<style>

</style>