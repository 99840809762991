<template>
  <div>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="card"
        persistent
      >
        <v-card class="position-fixed" color="#ECE5DD" dark>
        <v-toolbar
            color="#25D366"
            dark
            class="text--white"
        ><v-icon class="mr-3">mdi-whatsapp</v-icon>{{titulo}}
        <v-spacer></v-spacer>
        <v-card-actions class="justify-end">
            <v-btn
            text
            fab
            @click="$router.push({query: { ver: undefined }}); card = false"
            ><v-icon>mdi-close</v-icon></v-btn>
        </v-card-actions>
        </v-toolbar>
        <v-card-text>
            <slot />
        </v-card-text>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
    export default {
        data() {
            return {
                card: false,
            }
        },
        watch:{
            card() {
            },
            '$route.query.ver': function(){
                this.verificar()
            }
        },
        mounted(){
            this.verificar()
        },
        props: ['url', 'titulo'],
        methods:{
            verificar(){
                if(this.url == this.$route.query.ver){
                    this.card = true
                }
            }
        }
    }
</script>

<style>

</style>