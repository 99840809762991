<template>
    <v-col cols="6" xl="2" md="4" sm="4">
        <v-card :aspect-ratio="1/1" color="#000F3F" class="pa-3">
           <v-btn small @click="$router.push({query: { ver: url }})" color="#63C3D4" outlined block><span style="white-space: normal;">{{text}}</span></v-btn>
            <div style="text-align:center">
                <v-img height="200px" contain :src="img" alt="" />
            </div>
        </v-card>
    </v-col>
</template>

<script>
export default {
    props:[
        'text',
        'img',
        'url'
    ],
}
</script>

<style>

</style>