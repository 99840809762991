<template>
    <v-card id="Servicos" color="white" flat class="rounded-0 py-15">
        <v-container fluid>
            <div class="d-flex justify-center headers">
                <div>
                    <h3 class="text-h3 mb-10 primary--text" style="margin-bottom: 15px!important; font-family: Teko Medium!important;">SERVIÇOS</h3>
                    <div></div>
                </div>
            </div>
            <v-row justify="center">
                <Card text="Redes lógicas" :img="require('@/assets/Icons/SVG/featured/rede_logica.svg')" url="redes-logicas" />
                <Card text="Instalação WIFI" :img="require('@/assets/Icons/SVG/featured/wifi.svg')" url="wifi" />
                <Card text="SDAI" :img="require('@/assets/Icons/SVG/featured/incendio.svg')" url="sdai" />
                <Card :text="'CTRL Acesso'" :img="require('@/assets/Icons/SVG/featured/alarme_de_seguranca.svg')" url="controle-de-acesso" />
                <Card text="CFTV" :img="require('@/assets/Icons/SVG/featured/camera.svg')" url="cftv" />
                <Card text="Sonorização" :img="require('@/assets/Icons/SVG/featured/sonorizacao.svg')" url="sonorizacao" />
                <Card text="Energia Solar" :img="require('@/assets/Icons/SVG/featured/solar.svg')" url="energia-solar" />
                <!-- <Card2 text="Certificados" :src="require('@/assets/Icons/badge/selo-15-anos/SELODE15ANOS-GEOTECH3x.png')" :src-lazy="`${require('@/assets/Icons/badge/selo-15-anos/SELODE15ANOS-GEOTECH.png')}`" url="certificados" /> -->
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import Card from '@/components/homepage/Card'
//import Card2 from '@/components/homepage/Card2'
export default {
    components: {
        Card, 
        //Card2
    }
}
</script>