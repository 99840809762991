<template>
    <v-card color="grayscale" class="py-3 rounded-0" flat>
    <v-container>
        <vueper-slides
        class="no-shadow Parceiros mb-0"
        :visible-slides="$vuetify.breakpoint.mobile ? 3 : 6"
        :arrows="false"
        :fixed-height="$vuetify.breakpoint.mobile ? '50px':'150px'"
        fixed-width="250px"
        autoplay
        :gap="3"
        :dragging-distance="70"
        :bullets="false">
            <vueper-slide v-for="i in slides" :key="i" :image="i"></vueper-slide>
        </vueper-slides>
    </v-container>
    </v-card>
</template>

<style lang="scss">
    .Parceiros{
        .vueperslide{
            background-size: contain;
            filter: grayscale(1);
            transition: all 0.3s ease-in-out;
            &:hover{
                filter: none;
            }
        }
    }
</style>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default{
    components: { VueperSlides, VueperSlide },
    data: () => ({
        slides: [
            require('@/assets/parceiros/furukawa.svg'),
            require('@/assets/parceiros/bosch.svg'),
            require('@/assets/parceiros/hik.svg'),
            require('@/assets/parceiros/intelbras.svg'),
            require('@/assets/parceiros/morley.svg'),
            require('@/assets/parceiros/paradox.svg'),
            require('@/assets/parceiros/siemens.svg'),
            // require('@/assets/parceiros/fronius.svg'),
            // require('@/assets/parceiros/hoymiles.svg'),
            // require('@/assets/parceiros/intelbras-solar.svg'),
            // require('@/assets/parceiros/weg.svg'),
            // require('@/assets/parceiros/mikroTik.svg'),
            // require('@/assets/parceiros/UBIQUITI.svg'),
        ]
    })
}

</script>