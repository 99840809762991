<template>
    <div id="obras">
        <v-card color="white" class="rounded-0" flat>
            <v-container>
                <div class="d-flex justify-center headers">
                    <div>
                        <h3 class="text-h3 primary--text" style="margin-bottom: 15px!important; font-family: Teko Medium!important;">OBRAS</h3>
                        <div></div>
                    </div>
                </div>
            </v-container>
            <v-container>
                <vueper-slides   
                class="no-shadow"
                :arrows="false"
                :bullets="false"
                :visible-slides="$vuetify.breakpoint.mobile ? 3 : 7"
                autoplay
                :gap="2"
                fixed-height="150px"
                fixed-width="150px">
                    <vueper-slide v-for="(slide, i) in slides" :key="i" @click.native="clique(i)" :image="slide[0]"></vueper-slide>
                </vueper-slides>
                <v-dialog transition="dialog-bottom-transition" max-width="1000" :value="overlay">
                    <v-card color="primary">
                        <v-row class="ma-0 pa-0">
                            <v-col class="pa-0" cols="12" lg="8">
                                <v-carousel v-model="slideNum">
                                    <v-carousel-item
                                    v-for="(slide, i) in slides"
                                    :key="i"
                                    :src="slide[1]"
                                    :aspect-ratio="1/1"
                                    >
                                    </v-carousel-item>
                                </v-carousel>
                            </v-col>
                            <v-expand-transition>
                                <v-col v-if="slides[slideNum][2] != '' || slides[slideNum][3] != ''" cols="12" lg="4">
                                    <v-card dark color="dark-gray" :class="$vuetify.breakpoint.mobile ? 'pb-5':''">
                                        <v-container style="text-align: justify;  text-justify: inter-word">
                                            <div>{{slides[slideNum][3]}}</div> <br>
                                            {{slides[slideNum][2]}}
                                        </v-container>
                                    </v-card>
                                    <Logo color="white" class="ml-4" style="position: absolute; right: 15px; bottom: 15px; height: 20px; width: auto;" />
                                </v-col>
                            </v-expand-transition>
                        </v-row>
                    </v-card>
                </v-dialog>
            </v-container>
        </v-card>
    </div>
</template>

<script>
import Logo from "@/assets/Logo.vue"
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default{
    components: { VueperSlides, VueperSlide, Logo },
    data: () => ({
        slides: [
            [require('@/assets/obras/thumbnail/1.jpg'), require('@/assets/obras/large/1.jpg'), 'Execução de projeto SDAI composto por central de monitoramento e rede com aproximadamente 1.200 dispositivos. Implementação de projeto de sistema de CFTV com aproximadamente 430 pontos de vídeo monitoramento, central de vídeo monitoramento e configuração de servidores (NVR) com software de gerenciamento e gravação de imagens.', 'ORION HEALTH COMPLEX'],
            [require('@/assets/obras/thumbnail/forum.jpeg'), require('@/assets/obras/large/forum.jpg'), 'Execução do projeto de Telecomunicações composto por Cabeamento Estruturado Cat.6 Furukawa, links de fibra ótica internos e rede telefônica.', 'FÓRUM DE PATOS DE MINAS'],
            [require('@/assets/obras/thumbnail/arena.jpeg'), require('@/assets/obras/large/arena.jpg'), 'Execução do projeto de Telecomunicações composto por Cabeamento Estruturado Cat.6 Furukawa, links de fibra óptica internos e rede telefônica, executamos também o projeto de SDAI do empreendimento.', 'ARENA DE ESPORTE SÃO JOSÉ DOS CAMPOS - SP'],
            [require('@/assets/obras/thumbnail/prefeitura.jpeg'), require('@/assets/obras/large/prefeitura.jpg'), 'Execução do projeto de Telecomunicações composto por Sistema GPON Laserway Furukawa, executamos também os projetos de CFTV e SDAI.', 'PREFEITURA DE APARECIDA DE GOIÂNIA'],
            [require('@/assets/obras/thumbnail/hospital.jpeg'), require('@/assets/obras/large/hospital.jpeg'), 'Execução do projeto de Telecomunicações composto por Cabeamento Estruturado Cat.6 Furukawa, links de fibra optica internos e rede telefônica, executamos também os projetos de CFTV e CTRL Acesso.', 'HOSPITAL DAS CLÍNICAS UFG'],
            [require('@/assets/obras/thumbnail/tribunal.jpeg'), require('@/assets/obras/large/tribunal.jpg'), 'Execução do projeto de Telecomunicações composto por Cabeamento Estruturado Cat.6 Furukawa, links de fibra optica internos e rede telefônica, executamos também o projeto de SDAI Siemens.', 'TRIBUNAL DE JUSTIÇA'],
            [require('@/assets/obras/thumbnail/quartel.jpg'), require('@/assets/obras/large/quartel.jpg'), 'Execução do projeto de Telecomunicações composto por Cabeamento Estruturado Cat.6 Furukawa, links de fibra optica internos e rede telefônica, executamos também a ampliação do sistema de CFTV.', 'QUARTEL GENERAL EXÉRCITO - DF'],
            [require('@/assets/obras/thumbnail/estadio.jpeg'), require('@/assets/obras/large/estadio.jpg'), 'Execução do projeto de Telecomunicações composto por Cabeamento Estruturado Cat.6A BLINDADO Furukawa, links de fibra optica internos e rede telefônica, executamos também o projeto de SDAI do empreendimento.', 'ESTÁDIO OLÍMPICO DE GOIÂNIA'],
            [require('@/assets/obras/thumbnail/hugol.jpeg'), require('@/assets/obras/large/hugol.jpeg'), 'Execução do projeto de Telecomunicações composto por Cabeamento Estruturado Cat.6 Furukawa, links de fibra optica internos e rede telefônica, executamos também os projetos de CFTV e SDAI.', 'HUGOL GOIÂNIA'],
        ],
        overlay: false,
        slideNum: 0,
    }),
    methods:{
        clique(v){
            this.overlay = !this.overlay
            this.slideNum = v
        }
    }
}
</script>

<style lang="scss">
    .obras{
        .vueperslide{
            background-size: contain;
        }
    }
</style>