<template>
  <v-app id="Navigation">
    <v-card color="primary" class="rounded-0" flat>
        <v-system-bar v-if="$vuetify.breakpoint.mobile" height="66" color='primary' dark app>
            <Logo color="white" @click.native="$vuetify.goTo('#Overlay')" class="ml-4" style="height: 30%;" />
            <v-spacer></v-spacer>
            <v-btn @click.stop="openMenu = !openMenu" text depressed fab> <v-icon class="mr-0">mdi-menu</v-icon> </v-btn>
        </v-system-bar>
        <v-system-bar dark color="third" height="66" app v-if="!$vuetify.breakpoint.mobile">
            <Logo color="white" @click.native="$vuetify.goTo('#Overlay')" style="max-height: 60px; cursor: grab;" class="pa-5" />
            <v-spacer></v-spacer>
            <v-btn text @click="$vuetify.goTo('#QuemSomos')">A EMPRESA</v-btn>
            <v-btn text @click="$vuetify.goTo('#QuemSomos')">PREMIAÇÕES</v-btn>
            <v-btn text @click="$vuetify.goTo('#Servicos')">SERVIÇOS</v-btn>
            <v-btn text @click="$vuetify.goTo('#obras')">OBRAS</v-btn>
            <v-btn text @click="$router.push({query: { ver: 'contato' }})" class="mr-5">CONTATO</v-btn>
            <v-btn class="white--text mr-3" large icon>
                <v-img
                height="60px"
                width="60px"
                contain
                src="@/assets/Icons/badge/selo2.png"
                ></v-img>
            </v-btn>
            <v-btn class="white--text mr-3" large icon>
                <v-img
                height="60px"
                width="60px"
                contain
                src="@/assets/Icons/badge/selo1.png"
                ></v-img>
            </v-btn>
        </v-system-bar>
        <v-navigation-drawer width="300" class="drawer" color='primary' dark app :expand-on-hover="!$vuetify.breakpoint.smAndDown" 
        v-model="openMenu"
        mini-variant-width="70"
        >
            <v-list v-if="false" color="primary">
                <v-list-item>
                    <Logo color="white" class="pa-5" />
                </v-list-item>
            </v-list>
            <v-divider></v-divider>

            <v-list shaped>
                <v-list-item v-for="(item, i) in menu" :key="i" link @click="$router.push({query: { ver: item.url }})" class="pr-0 pl-1 lateral-bar mb-5">
                    <v-list-item-avatar width="75px" height="75px">
                        <v-img height="75px" width="75px" justify="center" contain :src="item.ico" />
                    </v-list-item-avatar>
                    <v-list-item-title class="font-weight-light">{{item.text}}</v-list-item-title>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-spacer></v-spacer>
        </v-navigation-drawer>

        <v-main background="secondary" :class="$vuetify.breakpoint.mobile ? '' : ''">
        <v-container fluid>
            <v-row>
                <v-col class="pa-0">
                    <slot></slot>
                </v-col>
            </v-row>
        </v-container>
        </v-main>
    </v-card>
  </v-app>
</template>
<script>

import Logo from "@/assets/Logo.vue"

export default{
    data() {
        return {
            menu: [
                {
                    ico: require('@/assets/Icons/SVG/rede_logica.svg'),
                    text: 'REDES LÓGICAS',
                    url: 'redes-logicas'
                },
                {
                    ico: require('@/assets/Icons/SVG/wifi.svg'),
                    text: 'Instalação de Wifi',
                    url: 'wifi'
                },
                {
                    ico: require('@/assets/Icons/SVG/incendio.svg'),
                    text: 'SDAI',
                    url: 'sdai'
                },
                {
                    ico: require('@/assets/Icons/SVG/alarme_de_seguranca.svg'),
                    text: 'CONTROLE DE ACESSO',
                    url: 'controle-de-acesso'
                },
                {
                    ico: require('@/assets/Icons/SVG/sonorizacao.svg'),
                    text: 'SONORIZAÇÃO',
                    url: 'sonorizacao'
                },
                {
                    ico: require('@/assets/Icons/SVG/camera.svg'),
                    text: 'CFTV',
                    url: 'cftv'
                },
                {
                    ico: require('@/assets/Icons/SVG/solar.svg'),
                    text: 'Energia Solar',
                    url: 'energia-solar'
                },
            ],
            openMenu: this.$vuetify.breakpoint.isMobile,
            mini: !this.$vuetify.breakpoint.smAndDown
        }
    },
    components: {
        Logo
    },
    mounted(){

    }
}
</script>
<style lang="scss">
::-webkit-scrollbar
{
	width: 10px;
	background-color: black;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, #000f3f), color-stop(0.72, #000a2c));
    border: 0.5px solid #5DB7C8;
}
//headers
.headers{
    div{
        display: flex;  
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        div{
            background-color: black;
            height: 2px;
            width: 60%;
            margin-bottom: 60px;
            padding-left: 30px;
        }
    }
}
.lateral-bar{
    transition: all 0.5s ease-out;
    background-color: black;
    &:hover{
        background-color: black;
    }
}
</style>