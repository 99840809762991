<template>
<v-card color="white" class="rounded-0 py-10" flat>
    <div class="quemsomos">
    <Particles
        id="QuemSomos"
        class="pb-0"
        :options="{
            background: {
                color: {
                    value: '#2D4B7D'
                }
            },
            fpsLimit: 40,
            interactivity: {
                detectsOn: 'canvas',
                events: {
                    onClick: {
                        enable: true,
                        mode: 'push'
                    },
                    onHover: {
                        enable: true,
                        mode: 'repulse'
                    },
                    resize: true
                },
                modes: {
                    bubble: {
                        distance: 400,
                        duration: 2,
                        opacity: 0.8,
                        size: 40
                    },
                    push: {
                        quantity: 4
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4
                    }
                }
            },
            particles: {
                color: {
                    value: '#63C3D4'
                },
                links: {
                    color: '#63C3D4',
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1
                },
                collisions: {
                    enable: true
                },
                move: {
                    direction: 'none',
                    enable: true,
                    outMode: 'bounce',
                    random: false,
                    speed: 2,
                    straight: false
                },
                number: {
                    density: {
                        enable: true,
                        value_area: 800
                    },
                    value: 80
                },
                opacity: {
                    value: 0.5
                },
                shape: {
                    type: 'circle'
                },
                size: {
                    random: true,
                    value: 5
                }
            },
            detectRetina: true
        }"
    />
        <div class="shade"></div>
        <div class="overlay">
            <v-container style="height: 100%;">
                <v-row align="center" justify="center" style="height: 100%;">
                    <v-col cols="12" lg="8" class="texto-cta-overlay">
                        <div class="d-flex justify-center headers mb-n5">
                            <div>
                                <h3 class="text-h3 title" style="font-family: Teko Medium!important;">QUEM SOMOS</h3>
                                <div></div>
                            </div>
                        </div>
                        Somos uma empresa de Engenharia premiada, fundada em 2005.
                        Há mais de 15 anos realizamos obras públicas e privadas no Centro-Oeste e em outras regiões do país.
                        Atendemos do desenvolvimento de projetos até a execução dos serviços dentro do perfil de atuação da nossa empresa.
                        Dispomos de uma equipe capacitada e qualificada pelos fabricantes.
                        <Certificados />
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</v-card>
</template>

<script>
import Certificados from '@/components/homepage/Certificados'
export default {
    components: { Certificados  }
}
</script>

<style lang="scss">
    $tamanho: 100vh;
    #QuemSomos{
        height: $tamanho;
    }
    .quemsomos{
        position: relative;
        background-color: black;
        height: $tamanho;
        min-height: 25rem;
        width: 100%;
        overflow: hidden;
        video {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            z-index: 0;
            -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
        }
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            pointer-events:none;
            // span de texto
            .texto-cta-overlay{
                font-size: 19px;
                text-align: center;
                color: white;
                text-transform: uppercase;
            }
            .shade{
                background-color: black;
                height: 200px;
                position: relative;
                transform: translateY(-100%);
            }
        }
    }
</style>
<style scoped lang="scss">
.headers{
    div{
        div{
            background-color: white!important;
        }
    }
}
</style>